@import url(https://fonts.googleapis.com/css2?family=Do+Hyeon&family=Noto+Sans+KR:wght@300;400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 16px;
}

#header {
  background-color: white;
  height: 150px;
}


#logo-desktop {
  height: 100px;
  margin-top: 0px;
}

#logo-text-desktop {
  color: black;
  text-align: left;
}

#footer {
  height: 200px;
  margin-top: 80px;
  margin-bottom: 0;
  background-color: black;
  line-height: 1.8rem;
  font-size: 0.9rem;
}
.SinglePostPage_postContent__1jtf9 img{
  max-width: 100%;
  height: auto;
  padding-right: 1em;
}

.SinglePostPage_postContent__1jtf9 section {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.SinglePostPage_postContent__1jtf9 section iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}
