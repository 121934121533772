.postContent img{
  max-width: 100%;
  height: auto;
  padding-right: 1em;
}

.postContent section {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.postContent section iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}