@import url('https://fonts.googleapis.com/css2?family=Do+Hyeon&family=Noto+Sans+KR:wght@300;400;700&display=swap');

.App {
  text-align: center;
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 16px;
}

#header {
  background-color: white;
  height: 150px;
}


#logo-desktop {
  height: 100px;
  margin-top: 0px;
}

#logo-text-desktop {
  color: black;
  text-align: left;
}

#footer {
  height: 200px;
  margin-top: 80px;
  margin-bottom: 0;
  background-color: black;
  line-height: 1.8rem;
  font-size: 0.9rem;
}